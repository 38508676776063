import { request, removeEmptyAttribute } from "../helpers/utility";

let instance = null;

export default class EventResources {
    constructor() {
        if (!instance) {
            this.url = 'events';
            this.admin_url = 'admin/events'
            this.admin_home_url = 'admin/events/home'
            this.admin_volunteers_url = 'admin/eventsvolunteers'
            this.admin_requirements_url = 'admin/eventsrequirements'
            instance = this;
        }
        return instance;
    }

    get() {
        return request.GET(this.url)
    }
    get_admin() {
        return request.GET(this.admin_url)
    }
    get_adminhomepage() {
        return request.GET(this.admin_home_url)
    }
    get_adminSingleEvent(eventId) {
        return request.GET(this.admin_url + '/' + eventId)
    }

    get_adminVolunteers(eventId) {
        return request.GET(this.admin_volunteers_url + '/' + eventId)
    }
    get_eventRequirements(eventId) {
        return request.GET(this.admin_requirements_url)
    }

    post_Volunteers(body) {
        let post_body = { ...body };
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.admin_volunteers_url, post_body)
    }

    delete_Volunteer(body) {
        let delete_body = { ...body };
        delete_body = removeEmptyAttribute(delete_body);
        return request.Fetch('DELETE', this.admin_volunteers_url, body, false)
    }


    post(body) {
        let post_body = { ...body };
        delete post_body['touched'];
        delete post_body['loginTouched'];
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.url, post_body)
    }
    admin_post(body) {
        let post_body = { ...body };
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.admin_url, post_body)
    }

    post_contact_us(body) {
        let post_body = { ...body };
        delete post_body['touched'];
        delete post_body['loginTouched'];
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.contact_us_url, post_body)
    }

    put(body) {
        let put_body = { ...body };
        //put_body['role_id'] = put_body['role']['value'];
        put_body = removeEmptyAttribute(put_body);
        return request.PUT(this.url + "/" + put_body['email'], put_body)
    }
    
    admin_put(body) {
        let put_body = { ...body };
        put_body = removeEmptyAttribute(put_body);
        return request.PUT(this.admin_url + "/" + put_body['id'], put_body)
    }

    post_password(body) {
        let post_body = { ...body };
        delete post_body['resetFieldTouched'];
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.password_reset_url, post_body)
    }

    put_password(body) {
        let put_body = { ...body };
        delete put_body['touched']
        put_body = removeEmptyAttribute(put_body);
        return request.PUT(this.password_update_url, put_body)
    }
}