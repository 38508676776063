import { request, removeEmptyAttribute } from "../helpers/utility";

let instance = null;

export default class BroadcastEmailResources {
    constructor() {
        if (!instance) {
            this.url = 'broadcastemail';
            instance = this;
        }
        return instance;
    }


    post(body) {
        let post_body = { ...body };
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.url, post_body)
    }        

    

    
}