import { request, removeEmptyAttribute } from "../helpers/utility";

let instance = null;

export default class AbsenceResources {
    constructor() {
        if (!instance) {
            this.url = 'absences';
            instance = this;
        }
        return instance;
    }

    get() {
        return request.GET(this.url)
    }   

    post(body) {
        let post_body = { ...body };
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.url, post_body)
    }        

    put(body) {
        let put_body = { ...body };
        //put_body['role_id'] = put_body['role']['value'];
        delete put_body['role']
        put_body = removeEmptyAttribute(put_body);
        return request.PUT(this.url + "/" + put_body['email'], put_body)
    }
    
    delete_absence(body) {
        let delete_body = { ...body };
        delete_body = removeEmptyAttribute(delete_body);
        return request.Fetch('DELETE', this.url, delete_body, false)
    }

    
}