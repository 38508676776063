import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import ContactResources from "../resources/ContactResources";

function validate(firstname, email, message) {
    return {
      firstname: firstname.length === 0,
      email: email.length === 0,
      message: message.length === 0,
    };
  }


function Contact(){
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        message:''
      });
    const resources = new ContactResources()

    const canBeSubmitted = (dataToSubmit) => {
        const errors = validate(dataToSubmit['firstname'], dataToSubmit['email'],  dataToSubmit['message']);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return !isDisabled;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        let submitFormData = formData
        if (!canBeSubmitted(submitFormData)){
            alert("Remember to fill out the mandatory fields")
            return
        }
        resources.post(submitFormData).then(res => {
            if (res.error.length < 1) {
                //toast.success("Thanks for registering!  Please check your email to confirm your email address."); 
                alert("Thanks for your message, Someone from the team will contact you.")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/about';
                  }, 200);
            } else {
                alert("Something went wrong, Please try again.")
            }
            }).catch((err) => {
                alert("Something went wrong, Please try again.")
            });

       
      };


    return(
        <Fragment>
            <Topbar/>

            {/* <!-- Hero Area --> */}
            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            <h1 style={{color:"#ffa800"}} className="block-title"><strong>Contact Us</strong></h1>
                        </div>
                    </div>
                </div>
            </div>

    {/* <!-- Main Content --> */}
    <div id="main-container">
 
    	<div className="content">  
        <h4 style={{color: "#ffa800"}} className="block-title"><strong>Our Locations Point of Contacts</strong></h4>          
        	<div className="container">
            	<div className="row">
                {/* <div className="col-md-3 col-sm-3 col-xs-6">
                    	<h4 className="block-title">Our Locations Point of Contacts</h4>
                    </div> */}
                	
                	<div className="col-md-3 col-sm-3 col-xs-6">
                        <address style={{color: "#ffa800"}} >
                        <h5><strong style={{color: "#003165"}}>LIBERTY POINT</strong></h5><br/>
                            Seye Oluwole<br/>
                            <br/><br/>                            
                        </address>
                    </div>
                	<div className="col-md-3 col-sm-3 col-xs-6">
                        <address style={{color: "#ffa800"}} >
                        <h5><strong style={{color: "#003165"}}>CANARY WHARF</strong></h5><br/>
                        Inioluwa Owoyemi<br/>
                            <br/><br/>
                            
                        </address>
                    </div>
                	<div className="col-md-3 col-sm-3 col-xs-6">
                        <address style={{color: "#ffa800"}} >
                        <h5><strong style={{color: "#003165"}}>NORTH LONDON.</strong></h5><br/>
                            Nathaniel<br/>
                            <br/><br/>
                            
                        </address>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-6">
                        <address style={{color: "#ffa800"}} >
                        <h5><strong style={{color: "#003165"}}>CROYDON</strong></h5><br/>
                            Javern<br/>
                            <br/><br/>
                            
                        </address>
                    </div>
                </div>
                <div className="row">
                    <strong style={{color: "#003165"}}>EMAIL US:</strong><h5 style={{color: "#ffa800"}}>av@thelibertychurchlondon.com</h5><br/>
                </div>
            </div>
            <div className="spacer-75"></div>
           	
            <div style={{backgroundColor: "#003165"}} className="accent-bg padding-tb20 cta-fw">
    			<div className="container">
                    
                    <h4 style={{color: "#ffa800"}}><strong>Can Also Use The Form, We respond ASAP</strong></h4>
                </div>
            </div>:<></>

            <div className="spacer-50"></div>
        	<div className="container">
            	<div className="row">
                	<div className="col-md-8 content-block">
                    	<form onSubmit={handleSubmit}>
                        	<div className="row">
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <input type="text" id="firstname" name="firstname"  value={formData.firstname}
                                                    onChange={handleInputChange} placeholder="First name*"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" id="lastname" name="lastname" value={formData.lastname}
                                                    onChange={handleInputChange} placeholder="Last name"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" id="email" name="email" value={formData.email}
                                                    onChange={handleInputChange} placeholder="Email*"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="number" id="phone" name="phone" value={formData.phone}
                                                    onChange={handleInputChange} placeholder="Phone"/>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="form-group">
                                        <textarea cols="6" rows="8" id="message" name="message" value={formData.message}
                                                    onChange={handleInputChange}  className="form-control input-lg" placeholder="Message*"></textarea>
                                    </div>
                                    <input style={{backgroundColor: "#003165"}} id="submit" name="submit" type="submit" className="btn btn-primary btn-lg pull-right" value="Submit"/>
                              	</div>
                           	</div>
                		</form>
                        <div className="clearfix"></div>
                        <div id="message"></div>
                    </div>                    
                    
                </div>
            </div>
        </div>
    </div>
            

            <Footer/>

        </Fragment>
    )
}

export default Contact