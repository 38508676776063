import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { ToastContainer, toast } from 'react-toastify';
import EventResources from "../resources/EventResources";
import Select from 'react-select';
import moment from "moment";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function validate(firstname, lastname, email, password, date_of_joining, date_of_birth, location_id) {
    return {
      firstname: firstname.length === 0,
      lastname: lastname.length === 0,
      email: email.length === 0,
      password: password.length === 0,
      date_of_joining: date_of_joining.length === 0,
      date_of_birth: date_of_birth.length === 0,
      location_id: location_id.length === 0,
    };
  }


function MediaBooking({isLoggedIn, loggedInUser, logout}){
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [eventDate, setEventDate] = useState(null);
    const [selectedReqItems, setSelectedReqItems] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [teamMembersOnly, setTeamMembersOnly] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        event_venue: '',
        description: '',
        event_date: '',
        start_time: '',
        end_time: '',
        number_attendees: null, // Assuming it's a Date object, initialize it appropriately
        contact_name: '', // Assuming it's a Date object, initialize it appropriately
        phone: '',
        other_detail: '',
        email: ''
      });   

    const eventImageAlbumId = 'ISdSkJy' // imgur album id
    const resources = new EventResources()
    const today = new Date();
    const handleEvRequirements = (reqs) => {
        setSelectedReqItems(reqs)
      };

    const handleTeamMembersOnlyEvent = (reqs) => {
        setTeamMembersOnly(reqs)
    };

    const handleEventDate = (date) => {
        setEventDate(date);
    };

    const handleTimeChange = (type, value) => {
        if (type === 'start') {
            setStartTime(value);
        } else if (type === 'end') {
            setEndTime(value);
        }
    };   

    const loadEventVolunteers = () => {
        return resources.get_eventRequirements()
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

    const canBeSubmitted = (dataToSubmit) => {
        const errors = validate(dataToSubmit['name'], dataToSubmit['event_venue'], dataToSubmit['description'], dataToSubmit['event_date'], dataToSubmit['start_time'],dataToSubmit['end_time'],dataToSubmit['event_requirements'], dataToSubmit['number_attendees'], dataToSubmit['contact_name'],dataToSubmit['email']);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return !isDisabled;
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
      };  

    const uploadToImgur = async () => {
        if (!imageFile) {
            alert('Please select a file.');
            return;
        }    

        try {
          const formData = new FormData();
          formData.append('image', imageFile);
          formData.append('album', eventImageAlbumId);
    
          const response = await fetch('https://api.imgur.com/3/image', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ` + process.env.REACT_APP_Bearer_Token.toString(),
            },
            body: formData,
          });
    
          const data = await response.json();
    
          if (data.success) {
            setImageUrl(data.data.link);         
            alert('Image Successfully uploaded. Now complete the rest of the form and submit.');
          } else {
            alert('Error Uploading Image, please try again');
            console.error('Error uploading image file:', data);
          }
        } catch (error) {
          alert('Error Uploading Image, please try again');
          console.error('Error uploading image:', error);
        }
      };   

    const handleSubmit = (e) => {
        e.preventDefault();
        let submitFormData = formData
        // ToDo: Fix this hack: get all data from state
        let evRequirements =[]        
        selectedReqItems.map((requirement) => (
            evRequirements.push(requirement['value'])
        ))
        const event_requirements = evRequirements.join(', ')
        submitFormData['image'] = imageUrl
        submitFormData['event_requirements'] = event_requirements
        submitFormData['event_date'] = moment( eventDate ).format('YYYY-MM-DD HH:mm:ss') 
        submitFormData['start_time'] = moment( eventDate ).format('YYYY-MM-DD ') + moment( startTime ).format('HH:mm:ss') 
        submitFormData['media_setup_time'] = moment( submitFormData['start_time'] ).subtract(90, 'minutes').format('YYYY-MM-DD HH:mm:ss')
        submitFormData['end_time'] = moment( eventDate ).format('YYYY-MM-DD ') + moment( endTime ).format('HH:mm:ss') 
        submitFormData['team_members_only'] = teamMembersOnly['value']
 
        if (!canBeSubmitted(submitFormData)){
            alert("Remember to fill out the mandatory fields")
            return
        }
        
        resources.admin_post(submitFormData).then(res => {
            if (res.error.length < 1) {
                //toast.success("Thanks for registering!  Please check your email to confirm your email address."); 
                alert("Thanks for the booking! Email Confirmation sent")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/events';
                  }, 200);
            } else {
                //toast.error("Operation Failed: " + res.error);
                alert("Registration Failed, please check details entered.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
        
    };

    useEffect(() => {      
        loadEventVolunteers().then(response => { 
            setRequirements(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });       
        
    }, []);
    
    let evRequirements = []  //dropdown values is the user information
    requirements.map((req, index) => (
        evRequirements.push({value: req['name'] + "-" + req['room'], label: req['name'] + "-" + req['room'] })
    ))

    let members_only= []
    members_only.push({value: true, label: 'Yes' })
    members_only.push({value: false, label: 'No' })
 

    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            <h1 style={{color:"#ffa800"}} className="block-title"><strong>Book the Team for an Event</strong></h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="spacer-10"></div>

            {/* <!-- Main Content --> */}
            <div id="main-container">
                <div className="content">
                    <div className="container">
                        <div className="text-align-center">
                            <h2>Tell us in Advance of an Upcoming Event</h2>
                        </div>
                    </div>
                    {/* <div className="padding-tb75 parallax parallax-light parallax1 counters" style={{backgroundImage: "url(assets/images/testjpeg1280.jpg)"}}>
                        <div className="overlay-accent"></div>
                        <div className="container">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                <h2 className="block-title">Please Fill the Form</h2>
                                </div>
                            
                        </div>
                    </div> */}
                    <div className="spacer-20"></div>
                    <div className="container" id="openings" style={{alignItems:"center"}}>
                        <div className="row" style={{alignItems:"center"}}>                	
                            <div className="col-md-10 col-sm-10 mx-auto">
                                <div className="well widget widget_volunteer_form">
                                    <h3>Enter The Event Details</h3>
                                    <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Name<span style={{color:"red"}}>*</span></label>
                                                    <input type="text" className="form-control"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleInputChange}
                                                    />  

                                        </div>
                                        <div className="col-md-6">
                                            <label>Location<span style={{color:"red"}}>*</span></label>
                                            <input type="text" className="form-control"
                                                name="event_venue"
                                                value={formData.event_venue}
                                                onChange={handleInputChange}
                                            />
                                        </div>                                         
                                                                                
                                        <label>Event Description<span style={{color:"red"}}>*</span></label>
                                        <input type="text" className="form-control"
                                            name="description"
                                            value={formData.description}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                            <label>Event Flyer</label>
                                                <input 
                                                className="form-control-alternative" 
                                                type="file" 
                                                name="productImages" 
                                                id="images" 
                                                onChange={handleImageChange}/>
                                                <button type="submit" onClick={uploadToImgur}>Upload</button>
                                        </div>
                                    <div className="row">
                                            <label>Event Date<span style={{color:"red"}}>*</span></label>
                                            <DatePicker
                                                    name="dateOfJoining"
                                                    placeholderText="Select Date"
                                                    value={eventDate}
                                                    onChange={handleEventDate}
                                                    minDate={today}
                                                    dateFormat="yyyy-MM-dd" // Set desired date format
                                                /> 
                                               
                                            <div className="spacer-20"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Event Start Time<span style={{color:"red"}}>*</span></label>
                                                <DateTime
                                                value={startTime}
                                                onChange={(value) => handleTimeChange('start', value)}
                                                dateFormat={false}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Event End Time<span style={{color:"red"}}>*</span></label>
                                                    <DateTime
                                                    value={endTime}
                                                    placeholder="Start Time"
                                                    onChange={(value) => handleTimeChange('end', value)}
                                                    dateFormat={false}
                                                />  
                                        </div>                                                                                             
                                    </div>   
                                        <div className="row">
                                            <label>Event Requirements<span style={{color:"red"}}>*</span></label>
                                            <Select
                                                name='event_requirements'
                                                id="event_requirements"
                                                classNamePrefix='select-css-tag'
                                                isMulti
                                                //value={selectedOption}
                                                onChange={handleEvRequirements}
                                                options={evRequirements}
                                                placeholder="..can select multiple"
                                                />                                                                                                                                 
                                        </div> 
                                        <div className="spacer-20"></div>
                                        <label>Number of Attendees<span style={{color:"red"}}>*</span></label> 
                                        <input type="number" className="form-control"
                                            name="number_attendees"
                                            value={formData.number_attendees}
                                            onChange={handleInputChange}
                                        />
                                        <label>Event Contact Person Name<span style={{color:"red"}}>*</span></label>
                                                <input type="text" className="form-control"
                                                    name="contact_name"
                                                    value={formData.contact_name}
                                                    onChange={handleInputChange}
                                                />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Event Contact Person Phone No</label>
                                                <input type="number" className="form-control"
                                                     name="phone"
                                                     value={formData.phone}
                                                     onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Event Contact Person Email<span style={{color:"red"}}>*</span></label>
                                                <input type="email" className="form-control"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>                                                                                             
                                        </div>                                                                          
                                       
                                        <div className="row">
                                            <label>Other Event Details</label>
                                            <input type="text" className="form-control"
                                                name="other_detail"
                                                value={formData.other_detail}
                                                onChange={handleInputChange}
                                            />                                    
                                        </div>
                                        
                                        {
                                            (isLoggedIn  && loggedInUser['role.name'] === 'admin') ? 
                                            <div className="row">
                                            <label>Team Members Only Event ?<span style={{color:"red"}}>*</span></label>
                                            <Select
                                                name='team_members_only'
                                                id="team_members_only"
                                                classNamePrefix='select-css-tag'
                                                //value={selectedOption}
                                                onChange={handleTeamMembersOnlyEvent}
                                                options={members_only}
                                                placeholder="Select one"
                                                />     
                                            </div>
                                            : <></>
                                        }
                                        
                                        <div className="spacer-30"></div>                                        
                                        <input style={{backgroundColor: "#003165"}} type="submit" className="btn btn-primary" value="Submit"/>
                                        <div className="spacer-10"></div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-4 sidebar-block">
                                <div className="widget widget_recent_causes">
                                    <h3 className="widgettitle">Please Check Email and Our Events page after confirmation.</h3>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <Footer/>     
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaBooking);