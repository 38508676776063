import * as types from '../actions/ActionTypes';

export const loginSuccess = () => ({
    type: types.LOGIN_SUCCESS,
});

export const logout = () => ({
    type: types.LOGOUT,
});

export const setUser = (user) => ({
    type: types.SET_USER,
    payload: user,
});