import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import UserResources from "../resources/UserResources";
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import moment from "moment";

function TeamMemberProfile({isLoggedIn, loggedInUser, logout}){
    const location = useLocation();
    const [user, setUser] = useState([])
    const [userId, setUserId] = useState('')
    const userResources = new UserResources()

    const loadUsers = (id, loggedInID) => {        
        return userResources.get_admin_single_user(id, loggedInID)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        //const searchParamss = queryString.parse(location.search)
        
        const userId = searchParams.get('mot');
        setUserId(userId)
        if (loggedInUser !== null){
            loadUsers(userId, loggedInUser['id']).then(response => { 
                setUser(response['body']); 
                toast.success("Successfully got data" )
            }).catch((err) => {
                toast.error("Network error: " + err)
            });
        }        
    }, []);

    const handleApproveUserButtonClick = (user) => { 
        userResources.admin_approve_user_post(user).then(res => {
            if (res.error.length < 1) {
                alert("User Approved")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/manageusers';
                  }, 200);
            }
            else {
                alert("Approval Failed.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      };

      const handleRefuseUserButtonClick = (user) => {      
        userResources.admin_refuse_user_post(user).then(res => {
            if (res.error.length < 1) {
                alert("User Refused. Speak to Seyi to Completely remove if needed")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/manageusers';
                  }, 200);
            }
            else {
                alert("Approval Process Failed.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      
  
      };   

    return(
        <Fragment>
             <Topbar/>  
                <div className="hero-area">
                <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{user['firstname']} {user['lastname'] + '\'s'} Profile </strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>No Access to this Page</strong></h1>
                            }
                        </div>
                    </div>
                </div>
            </div>
                  

                <div id="main-container">
                <div class="content">
                    {
                        isLoggedIn ?
                    
                    <div class="container">      
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell style={{alignContent : 'center'}}><img src={user['image']} style={{width: "200px", height:"200px"}} alt=""/></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Name</strong></TableCell>
                                    <TableCell>{user['firstname']} {user['lastname']}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Joined Media Team</strong></TableCell>
                                    <TableCell>{moment( user['date_of_joining']).format('MMMM YYYY')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Bio</strong></TableCell>
                                    <TableCell>{user['about']}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Media Skills</strong></TableCell>
                                    <TableCell>{user['competencies']}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Primary Location</strong></TableCell>
                                    <TableCell>{user['location.name']}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Birthday</strong></TableCell>
                                    <TableCell>{moment( user['date_of_birth']).format('DD MMMM')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Done Connect</strong></TableCell>
                                    <TableCell>{user['done_connect'] === 'True' ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Done Fortify</strong></TableCell>
                                    <TableCell>{user['done_fortify'] === 'True' ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Done Serve</strong></TableCell>
                                    <TableCell>{user['done_serve'] === 'True' ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Team Role</strong></TableCell>
                                    <TableCell>{user['role.name']}</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                
                                </TableBody>
                            </Table>
                        </TableContainer>         
                    </div>:
                    <></>
                    }

                <div className="spacer-40"></div>

                    { (loggedInUser !== null && loggedInUser['role.name'] === 'admin' && user['enabled'] === '0') ?
                    <div>
                        <button style={{ backgroundColor : "#8ccd80", color : "#003165" , marginLeft: "10px"}} onClick={() => handleApproveUserButtonClick(user)} >Approve User</button>
                        
                        <button style={{backgroundColor : "#d80000", color : "#003165" , marginLeft: "10px"}} onClick={() => handleRefuseUserButtonClick(user)} >Refuse User</button>
                    </div>
                        :<></>
                    }
                </div>
            </div>

                <Footer/>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberProfile);