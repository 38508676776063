import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import UserResources from "../resources/UserResources";
import RotaResources from "../resources/RotaResources";
import LocationResources from "../resources/LocationResources";
import { toast } from "react-toastify";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import { connect } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Select from 'react-select';
import moment from "moment";
import {ACCESS_DENIED} from '../Messages'

function Rota({isLoggedIn, loggedInUser, logout}){

    const [users, setUsers] = useState([])
    const [servicenames, setServiceNames] = useState([])
    const [rota, setRota] = useState(null)
    const [rotaDates, setRotaDates] = useState([])
    const [locations, setLocations] = useState([])
    
    let rota_resources = new RotaResources()
    const locationResources = new LocationResources()

    const loadUsers = () => {
        let resources = new UserResources()
        return resources.get_admin()
    }

    const loadRota = (date) => {
        return rota_resources.get_rota_detail(date)
    }

    const loadRotaDates = () => {        
        return rota_resources.get_rota_dates()
    }

    const loadLocations = () => {
        return locationResources.get()
    }

    const handleRotaDateSelection = (selectedDate) => {
        loadRota(selectedDate.value).then(response => { 
            setRota(response['body']);
            setServiceNames(Object.keys(response['body'])); 
        }).catch((err) => { 
            toast.error("Network error: " + err)
        });
    };

    const handleSelection = () => {

        // setFormData((prevData) => ({
        //   ...prevData,
        //   location_id: selectedOptions['value'],
        // }));
      };

    useEffect(() => {
        loadUsers().then(response => { 
            setUsers(response['body']); 
        }).catch((err) => { 
            toast.error("Network error: " + err)
        });

        loadLocations().then(response => { 
            setLocations(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });


        loadRotaDates().then(response => { 
            setRotaDates(response['body'][0]['event_dates']); 
        }).catch((err) => { 
            toast.error("Network error: " + err)
        });

    }, []);

    const rota_dates = rotaDates.map((rota_date, index) => ({
        value:  rota_date,
        label: moment(rota_date, "DD-MM-YYYY HH:mm:ss").format('DD MMMM')
    }));

    let mediaLocations = []  
    locations.map((loc, index) => (
        mediaLocations.push({value: loc['id'], label: loc['name'] })
    ))
    

    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">                    
                        <div className="page-banner-text">
                            {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Rota</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }                            
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
            {
                isLoggedIn ?
        	<div className="container">

                <div className="spacer-40"></div> 
                <div>
                    <div className="row">
                        <div className="col-md-6">
                            <label style={{color:"#ffa800"}} >Location</label>
                                    <Select
                                        name='requirements'
                                        id="requirements"
                                        classNamePrefix='select-css-tag'
                                        //value={selectedOption}
                                        onChange={handleSelection}
                                        options={mediaLocations}
                                        placeholder="Select Location" 
                                        isDisabled={true}
                                    />  
                        </div>

                        <div className="col-md-6">
                            <label style={{color:"#ffa800"}} >Available Rota Dates</label>
                                <Select
                                    name='rota_date'
                                    id="rota_date"
                                    classNamePrefix='select-css-tag'
                                    onChange={handleRotaDateSelection}
                                    options={rota_dates}
                                    placeholder="Select the date"
                                /> 
                            </div>  
                        </div>
                    </div>    

                <div className="spacer-40"></div> 
                <div>
                    {
                        servicenames.map((service_name) =>
                        <div>
                            <div className="spacer-50"></div>  
                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <h4 style={{color:"#ffa800"}} className="accent-color short"><strong>Rota for</strong></h4>
                                        <h1 style={{color:"#003165"}} >{service_name} </h1>
                                    </div>
                                </div> 

                            
                            <div className="container">                         
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
        
                                            <TableCell style={{alignContent : 'center'}}>
                                            
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        
                                    {
                                        Object.keys(rota[service_name][['users_by_roles']]).map((user_role) =>{
                                         return(
                                            <TableRow>
                                                <TableCell><strong>{user_role}</strong></TableCell> 
                                                 
                                                  {
                                                    rota[service_name][['users_by_roles']][user_role].map((rota_user) =>
                                                    <TableCell><div className="grid-item grid-staff-item grid-staff-item-compact">
                                                    <div className="grid-item-inner">
                                                        <div className="media-box">
                                                            <img src={rota_user['image']} style={{width: "80px", height:"80px"}} alt=""/>
                                                            <span  style={{color:"#003165"}} className="meta-data"><strong>{rota_user['firstname']}</strong></span>
                                                                <div className="spacer-30"></div> 
                                                            </div>

                                                        {/* <div className="grid-item-content">
                                                        <span  style={{color:"#0096FF"}} className="meta-data"><strong>{rota_user['firstname']}</strong></span>
                                                        </div> */}
                                                        </div>
                                                        </div>
                                                    </TableCell>
                                                    )
                                                 }                                               
                                                
                                            
                                            </TableRow>
                                         
                                        )})
                                    }                                    
                                    
                                    </TableBody>                                                                    
                                        
                                    </Table>
                                </TableContainer>         
                            </div> 
                        </div>                        
                        )}  
                </div>
                
                
                <h3> <a style={{color:"#ffa800"}} href="https://docs.google.com/spreadsheets/d/1rK4UaLqlKfjmkWdAyJOPPCckwJkO8FlrRcDW5MEobwE/edit#gid=0" target="_blank">Full Month Rota</a></h3>

            </div>   :<></> 
               
            }          
          

            </div>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Rota);