import * as types from '../actions/ActionTypes';

const initialState = {
    isLoggedIn: false,
    user: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,                
            };
            
        case types.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case types.SET_USER:
            return {
                ...state,
                user: action.payload,
            };
        default:
            return state;
    }
};

export default authReducer;
