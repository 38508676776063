import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import ProcessImprovementsResources from "../resources/ProcessImprovementsResources";
import UserResources from "../resources/UserResources";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment/moment";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { event } from "jquery";
import Select from 'react-select';
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import {ACCESS_DENIED} from '../Messages'

function ProcessImprovements({isLoggedIn, loggedInUser}){
    const location = useLocation();

    const [improvements, setImprovements] = useState([])
    const [users, setUsers] = useState([])
    const [selectedOption, setSelectedOption] = useState({}); // stores the entire user details
    const [suggestedImprovement, setSuggestedImprovement] = useState('');
    const resources = new ProcessImprovementsResources()
  
    // Function to handle dropdown change
    const handleDropdownChange = (event) => {
      setSelectedOption(event.value);
    };
  
    // Function to handle textbox change
    const handleSuggestedImprovementChange = (event) => {
        setSuggestedImprovement(event.target.value);
    };
  
    // Function to handle button clicks
    const handleAddImprovementButtonClick = () => {   
      
      if(Object.keys(selectedOption).length === 0)
      {
        toast.info("Please Select your Name")
        return;
      }
      let suggestion = {};
      suggestion['user_id'] = selectedOption['id']
      if(suggestedImprovement.length > 1 )
      {
        suggestion['name'] = suggestedImprovement
      }
      suggestion['date_added'] = moment( new Date()).format('YYYY-MM-DD HH:mm:ss') 
      resources.post(suggestion)
      
      toast("Thanks for the suggestion", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        });

        setTimeout(() => {
            window.location.href = window.location.origin + '/improvements';
          }, 200);
    };

    // Function to handle button clicks
    const handleDeleteImprovementButtonClick = (id) => {      
      let suggestion = {};
      suggestion['id'] = Number(id)
      suggestion['user_email'] = loggedInUser['email']

      resources.delete_Volunteer(suggestion).then(res => {
        if (res.error.length < 1) {
            alert("Successfully updated")
            setTimeout(() => {
                window.location.href = window.location.origin + '/improvements';
              }, 200);
        }else if(res.error === 'You do not have permission to make this change') {
            alert("You do not have permission to make this change.")
        }
         else {
            //toast.error("Operation Failed: " + res.error);
            alert("An Issue occured, please retry")
        }
        }).catch((err) => {
            toast.error("Network Error: " + err);
        });

    };    

    const loadImprovements = () => {        
        return resources.get()
    }

  
    const loadUsers = () => {
        let userResources = new UserResources()
        return userResources.get_admin()
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        //const searchParamss = queryString.parse(location.search)
        
         loadImprovements().then(response => { 
            setImprovements(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
        

        loadUsers().then(response => { 
            setUsers(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
        
    }, []);

    let members = []  //dropdown values is the user information
    users.map((user, index) => (
        members.push({value: user, label: user['firstname'] + " " + user['lastname'] })
    ))
    return(        
        <Fragment>
            <Topbar/>
             <div className="hero-area">
             <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Process Improvements</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }                             
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--Main Content> */}
            <div id="main-container">
                <div class="content">
                {
                    isLoggedIn ? 
                    <div class="container">                          
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell><strong>Action to Take</strong></TableCell>
                                    <TableCell><strong>Added by</strong></TableCell>
                                    <TableCell><strong>Date Added</strong></TableCell>
                                    <TableCell><strong></strong></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {improvements.map((improvement) => (
                                    <TableRow key={improvement['id']}>
                                        <TableCell>{improvement['name']}</TableCell>  
                                        <TableCell>{improvement['user.firstname'] + " " + improvement['user.lastname']}</TableCell>
                                        <TableCell>{moment( improvement['date_added']).format('DD MMMM YYYY')}</TableCell>         
                                        <TableCell>{loggedInUser !== null && loggedInUser['role.name'] === 'admin'? <button style={{marginLeft: "10px"}} onClick={() => handleDeleteImprovementButtonClick(improvement['id'])}>Delete</button> : <p></p>}</TableCell>                                     
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>    

                        <div class="spacer-20"></div>
                        <div class="spacer-20"></div>
                        
                            <div>
                                <h3 className="post-title"><p style={{color: "#003165"}}>Add your suggestions</p></h3>                        
                                        <div className="row">
                                            <Select
                                                name='members'
                                                id="members"
                                                classNamePrefix='select-css-tag'
                                                //value={selectedOption}
                                                onChange={handleDropdownChange}
                                                options={members}
                                                placeholder="Team Members"
                                                />
                                            
                                            <input style={{marginLeft: "10px", marginTop: "10px"}}
                                                type="text"
                                                value={suggestedImprovement}
                                                onChange={handleSuggestedImprovementChange}
                                                placeholder="Enter details"
                                            />
                                            <button style={{marginLeft: "10px", width:'100px'}} onClick={handleAddImprovementButtonClick}>Add</button>
                                        </div> 

                                </div>
                                :
                                <p></p>
                        
                           
                    </div> : <></>
                }
                </div>
            </div>
            <Footer/>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessImprovements);