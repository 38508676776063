import React, { Fragment, useState, useEffect  } from "react";
import Topbar from "../layouts/TopBar";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import UserResources from "../resources/UserResources";
import moment from "moment";
import { toast } from "react-toastify";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import { connect } from "react-redux";
import {ACCESS_DENIED} from '../Messages'

function TeamBirthdays({isLoggedIn, loggedInUser, logout}){
    const [users, setUsers] = useState([])

    const loadUsers = () => {
        let resources = new UserResources()
        return resources.get_admin_birthday()
    }

    useEffect(() => {
        loadUsers().then(response => { 
            setUsers(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
    }, []);

    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Team Member Birthdays</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }                            
                        </div>
                    </div>
                </div>
            </div>

            <div id="main-container">
                <div class="content">
                {
                    isLoggedIn ?
                    <div class="container">      
                        <h6 style={{"height":"25%"}}><img src="https://i.imgur.com/BQdCdB8.jpg" style={{width: "1000px", height:"300px"}} alt=""/></h6>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell><strong>FirstName</strong></TableCell>
                                    <TableCell><strong>LastName</strong></TableCell>
                                    <TableCell><strong>Birthday</strong></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {users.map((user) => (
                                    <TableRow key={user['id']}>
                                        <TableCell>{user['firstname']}</TableCell>
                                        <TableCell>{user['lastname']}</TableCell>
                                        <TableCell>{moment( user['date_of_birth']).format('DD MMMM')}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>         
                    </div>:<></>
                }
                </div>
            </div>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamBirthdays);