import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import UserResources from "../resources/UserResources";
import { toast } from "react-toastify";
import moment from "moment/moment";


function SkillsMatrix(){
    const [users, setUsers] = useState([])

    const loadUsers = () => {
        let resources = new UserResources()
        return resources.get_admin()
    }

    useEffect(() => {
        loadUsers().then(response => { 
            setUsers(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
    }, []);

    return(
        <Fragment>
                <Topbar/>

                <div className="hero-area">
                    <div className="page-banner parallax" style={{backgroundImage: "url(assets/images/testjpeg1280.jpg)"}}>
                        <div className="container">
                            <div className="page-banner-text">
                                <h1 className="block-title">TEAM SKILL SET</h1>
                            </div>
                        </div>
                    </div>
                </div>

                     {/* <!--Main Content> */}
            <div id="main-container">
                <div class="content">
                    <div class="container">      
                        <h3>Keep Upskilling</h3>
                        <a href="/improvements">Find </a>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell><strong>FirstName</strong></TableCell>
                                    <TableCell><strong>LastName</strong></TableCell>
                                    <TableCell><strong>Skills</strong></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {users.map((user) => (
                                    <TableRow key={user['id']}>
                                        <TableCell>{user['firstname']}</TableCell>
                                        <TableCell>{user['lastname']}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>         
                    </div>
                </div>
            </div>

        <Footer/>

        </Fragment>
    )
}

export default SkillsMatrix