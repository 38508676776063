import React, { Fragment } from "react";

function Footer() {
  return (   
    <Fragment>
        {/* <!-- Site Footer --> */}
    <div className="site-footer parallax parallax3" style={{backgroundColor:"#003165"}}> 
    {/* image size 1400 by 800 */}
    	<div className="container">
        	<div className="row">
            	<div className="col-md-4 col-sm-4">
                	<div className="widget footer_widget">
                    	<h4 className="widgettitle">TLC Media Team</h4>
                        <p><img src="images/logo.png" alt=""/></p>
                        <p>The Media Team of the The Liberty Church London</p>
                        <ul className="social-icons-rounded social-icons-colored">
                        <li className="facebook"><a href="https://www.facebook.com/TLChurchLondon/" target="_blank"><i className="fa fa-facebook-f"></i></a></li>
                            <li className="instagram"><a href="https://www.instagram.com/tlchurchlondon/" target="_blank"><i className="fa fa-instagram"></i></a></li>
					        <li className="youtube"><a href="https://www.youtube.com/@TheLibertyChurchLondon" target="_blank"><i className="fa fa-youtube-play"></i></a></li>
				
                        </ul>
                    </div>
                </div>
            	<div className="col-md-4 col-sm-4">
                	<div className="widget footer_widget widget_links">
                    	<h4 className="widgettitle">Quick Links</h4>
                        <ul>
                        	<li><a href="https://www.thelibertychurchlondon.com/" target="_blank">The Liberty Church London Main site</a></li>
                        	<li><a href="/contact">Contact Us</a></li>
                        	<li><a href="/about">Meet our team</a></li>
                        </ul>
                    </div>
                </div>
            	{/* <div className="col-md-4 col-sm-4">
                	<div className="widget footer_widget">
                    	<h4 className="widgettitle">We are on Twitter</h4>
                        <div className="twitter-widget" data-tweets-count="2"></div>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
    {/* <!-- Site Footer --> */}
    <div className="site-footer-bottom">
    	<div className="container">
        	<div className="row">
            	<div className="col-md-6 col-sm-6">
                	<div className="copyrights-col-left">
                    	<p>&copy; 2024 TLC Media. All Rights Reserved</p>
                    </div>
                </div>
            	<div className="col-md-6 col-sm-6"></div>
                	{/* <div className="copyrights-col-right">
                    	<ul className="footer-menu">
                        	<li><a href="privacy-policy.html">Privacy policy</a></li>
                        	<li><a href="payment-terms.html">Payment terms</a></li>
                        	<li><a href="refund-policy.html">Refund policy</a></li>
                        </ul>
                    </div> */}
           	</div>
      	</div>
  	</div>
  	 <a id="back-to-top"><i className="fa fa-angle-double-up"></i></a> 
    

    </Fragment> 
    
  );
}

export default Footer;
