import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import UserResources from "../resources/UserResources";
import { toast } from "react-toastify";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import moment from "moment";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";

function TeamDistribution({isLoggedIn, loggedInUser}){

    const [users, setUsers] = useState([])
    const [LPUsers, setLPUsers] = useState([])
    const [NLUsers, setNLUsers] = useState([])
    const [CWUsers, setCWUsers] = useState([])
    const [CRYUsers, setCRYUsers] = useState([])
    

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5, // Adjust based on your need
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    const loadUsers = () => {
        let resources = new UserResources()
        return resources.get_admin()
    }

    useEffect(() => {
        loadUsers().then(response => { 
            setUsers(response['body']); 
            setNLUsers(response['body'].filter(user => user['location.name'] === 'North London' )); 
            setCWUsers(response['body'].filter(user => user['location.name'] === 'Canary Wharf' )); 
            setLPUsers(response['body'].filter(user => user['location.name'] === 'Liberty Point' )); 
            setCRYUsers(response['body'].filter(user => user['location.name'] === 'Croydon' )); 
            toast.success("Successfully got data" )
        }).catch((err) => { 
            toast.error("Network error: " + err)
        });
    }, []);


    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            <h1 style={{color:"#ffa800"}} className="block-title"><strong>Team Distribution</strong></h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
        	<div className="container">

                <div className="spacer-40"></div>     

            	<div className="row">
                    <div className="col-md-6 col-sm-6">
                    	<h4 style={{color: "#003165"}}className="accent-color short">Team at</h4>
                    	<h1 style={{color: "#ffa800"}}>Liberty Point</h1>
                    </div>
                </div>
                <div className="spacer-40"></div>    
                {                         
                        LPUsers.length === 1 ?                       
                            (
                                <div >
                                    {
                                        isLoggedIn ? 
                                        <div>
                                                <a href={"/memberprofile?pid=3&mot=" + LPUsers[0]['id']}><img src={LPUsers[0]['image']} style={{width: "200px", height:"200px"}} alt=""/></a>
                                                <p><a  href={"/memberprofile?pid=3&mot=" + LPUsers[0]['id']}>{LPUsers[0]['firstname'] + " " + LPUsers[0]['lastname'] }</a></p>
                                        </div>
                                            :
                                        <div>
                                            <img src={LPUsers[0]['image']} style={{width: "200px", height:"200px"}} alt=""/>
                                            <p style={{color: "#ffa800"}}>{LPUsers[0]['firstname'] + " " + LPUsers[0]['lastname'] }</p>
                                        </div>
                                    }
                                    
                                </div>
                            )
                            
                            :
                            
                            (
                            <Slider {...settings}>
                            { 
                                LPUsers.map((member, index) => { return(
                                        <div key={index} style={{ textAlign : "center"}}>
                                            {isLoggedIn ?
                                                <div>
                                                    <a href={"/memberprofile?pid=3&mot=" + member['id']}><img src={member['image']} style={{width: "200px", height:"200px"}} alt=""/></a>
                                                    <a style={{color: "#ffa800"}} href={"/memberprofile?pid=3&mot=" + member['id']}>{member['firstname'] + " " + member['lastname'] }</a>
                                                </div> 
                                                :
                                                <div>
                                                    <img src={member['image']} style={{width: "200px", height:"200px"}} alt=""/>
                                                    <p style={{color: "#ffa800"}}>{member['firstname'] + " " + member['lastname'] }</p>
                                                </div> 
                                            }
                                            
                                        </div>
                                    )})
                                }
                        </Slider>
                            )

                    }
               
                </div>
            <div className="spacer-50"></div>

            {/* Canary Wharf */}
            <div className="container">
            	<div className="row">
                    <div className="col-md-6 col-sm-6">
                    	<h4 style={{color: "#003165"}} className="accent-color short">Team at</h4>
                    	<h1 style={{color: "#ffa800"}}>Canary Wharf</h1>
                    </div>
                </div>
                <div className="spacer-40"></div>
                    { 
                        CWUsers.length === 1  ?                       
                            
                                <div >
                                    {
                                        isLoggedIn ? 
                                        <div>
                                                <a href={"/memberprofile?pid=3&mot=" + CWUsers[0]['id']}><img src={CWUsers[0]['image']} style={{width: "200px", height:"200px"}} alt=""/></a>
                                                <p><a  href={"/memberprofile?pid=3&mot=" + CWUsers[0]['id']}>{CWUsers[0]['firstname'] + " " + CWUsers[0]['lastname'] }</a></p>
                                        </div>
                                            :
                                        <div>
                                            <img src={CWUsers[0]['image']} style={{width: "200px", height:"200px"}} alt=""/>
                                            <p style={{color: "#ffa800"}}>{CWUsers[0]['firstname'] + " " + CWUsers[0]['lastname'] }</p>
                                        </div>
                                    }                                    
                                </div>
                            
                            :
                            
                            <Slider {...settings}>
                            { 
                            CWUsers.map((member, index) => {
                                     return(
                                    <div key={index} style={{ textAlign : "center"}}>
                                        {isLoggedIn ?
                                            <div>
                                                <a href={"/memberprofile?pid=3&mot=" + member['id']}><img src={member['image']} style={{width: "200px", height:"200px"}} alt=""/></a>
                                                <a style={{color: "#ffa800"}} href={"/memberprofile?pid=3&mot=" + member['id']}>{member['firstname'] + " " + member['lastname'] }</a>
                                            </div> 
                                            :
                                            <div>
                                                <img src={member['image']} style={{width: "200px", height:"200px"}} alt=""/>
                                                <p style={{color: "#ffa800"}}>{member['firstname'] + " " + member['lastname'] }</p>
                                            </div> 
                                        }
                                    </div>
                                )})
                            }
                        </Slider>

                    }       
                </div>
                {/* <div className="carousel-wrapper">
                    <div className="row">
                        <ul className="owl-carousel carousel-fw" id="causes-slider" data-columns="5" data-autoplay="" data-pagination="yes" data-arrows="no" data-single-item="no" data-items-desktop="5" data-items-desktop-small="3" data-items-tablet="2" data-items-mobile="1">
                        {
                            users.map((user, index) => {  
                                if (user['location.name'] === 'Canary Wharf'){
                                    return(
                                        <li className="item">
                                            <div className="grid-item cause-grid-item small-business format-standard">
                                                <div className="grid-item-inner">
                                                    <a href={"/memberprofile?pid=3&mot=" + user['id']} className="media-box">                                                     
                                                        <img src={user['image']} style={{width: "100px", height:"100px"}} alt=""/>    
                                                        </a>                                               
                                                    <div className="grid-item-content">
                                                        <a className="cProgress" data-complete="88" data-color="F23827" data-toggle="tooltip" data-original-title="10 days left"><strong></strong></a>
                                                        <h3 className="post-title">{user['firstname'] + " " + user['lastname']}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                            })
                        }
                        </ul>
                    </div>
                </div> */}
            
            <div className="spacer-50"></div>
            {/* North London */}
            <div className="container">
            	<div className="row">
                    <div className="col-md-6 col-sm-6">
                    	<h4 style={{color: "#003165"}} className="accent-color short">Team at</h4>
                    	<h1 style={{color: "#ffa800"}}>North London</h1>
                    </div>
                </div>
                <div className="spacer-40"></div>
                { 
                        NLUsers.length === 1  ?                        
                            
                                <div >
                                    {
                                        isLoggedIn ? 
                                        <div>
                                                <a href={"/memberprofile?pid=3&mot=" + NLUsers[0]['id']}><img src={NLUsers[0]['image']} style={{width: "200px", height:"200px"}} alt=""/></a>
                                                <p><a  href={"/memberprofile?pid=3&mot=" + NLUsers[0]['id']}>{NLUsers[0]['firstname'] + " " + NLUsers[0]['lastname'] }</a></p>
                                        </div>
                                            :
                                        <div>
                                            <img src={NLUsers[0]['image']} style={{width: "200px", height:"200px"}} alt=""/>
                                            <p style={{color: "#ffa800"}}>{NLUsers[0]['firstname'] + " " + NLUsers[0]['lastname'] }</p>
                                        </div>
                                    }                                      
                                </div>
                            
                            :
                            
                            <Slider {...settings}>
                            { 
                            NLUsers.map((member, index) => {return(
                                    <div key={index} style={{ textAlign : "center"}}>
                                        {isLoggedIn ?
                                            <div>
                                                <a href={"/memberprofile?pid=3&mot=" + member['id']}><img src={member['image']} style={{width: "200px", height:"200px"}} alt=""/></a>
                                                <a style={{color: "#ffa800"}} href={"/memberprofile?pid=3&mot=" + member['id']}>{member['firstname'] + " " + member['lastname'] }</a>
                                            </div> 
                                            :
                                            <div>
                                                <img src={member['image']} style={{width: "200px", height:"200px"}} alt=""/>
                                                <p style={{color: "#ffa800"}}>{member['firstname'] + " " + member['lastname'] }</p>
                                            </div> 
                                        }                                        
                                    </div>
                                )})
                            }
                        </Slider>

                    }
            </div>

            {/* Croydon */}
            <div className="spacer-50"></div>
            <div className="container">
            	<div className="row">
                    <div className="col-md-6 col-sm-6">
                    	<h4 style={{color: "#003165"}} className="accent-color short">Team at</h4>
                    	<h1 style={{color: "#ffa800"}}>Croydon</h1>
                    </div>
                </div>
                <div className="spacer-40"></div>
                
                { 
                        CRYUsers.length === 1?                       
                            
                                <div >
                                    {
                                        isLoggedIn ? 
                                        <div>
                                                <a href={"/memberprofile?pid=3&mot=" + CRYUsers[0]['id']}><img src={CRYUsers[0]['image']} style={{width: "200px", height:"200px"}} alt=""/></a>
                                                <p><a  href={"/memberprofile?pid=3&mot=" + CRYUsers[0]['id']}>{CRYUsers[0]['firstname'] + " " + CRYUsers[0]['lastname'] }</a></p>
                                        </div>
                                            :
                                        <div>
                                            <img src={NLUsers[0]['image']} style={{width: "200px", height:"200px"}} alt=""/>
                                            <p style={{color: "#ffa800"}}>{CRYUsers[0]['firstname'] + " " + CRYUsers[0]['lastname'] }</p>
                                        </div>
                                    }                                     
                                </div>
                            
                            :
                            
                            <Slider {...settings}>
                            { 
                            CRYUsers.map((member, index) => { return(
                                    <div key={index} style={{ textAlign : "center"}}>
                                        {isLoggedIn ?
                                            <div>
                                                <a href={"/memberprofile?pid=3&mot=" + member['id']}><img src={member['image']} style={{width: "200px", height:"200px"}} alt=""/></a>
                                                <a style={{color: "#ffa800"}} href={"/memberprofile?pid=3&mot=" + member['id']}>{member['firstname'] + " " + member['lastname'] }</a>
                                            </div> 
                                            :
                                            <div>
                                                <img src={member['image']} style={{width: "200px", height:"200px"}} alt=""/>
                                                <p style={{color: "#ffa800"}}>{member['firstname'] + " " + member['lastname'] }</p>
                                            </div> 
                                        }
                                    </div>
                                )})
                            }
                        </Slider>

                    }                
            </div>

            </div>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

export default connect(mapStateToProps)(TeamDistribution);