import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import authReducer from './LoggedInReducer'; // Import your authReducer


const persistConfig = {
    key: 'root',
    storage,
  };
  
  const rootReducer = combineReducers({
    authReducer,
    // Add other reducers here
  });
  //authReducer: persistReducer(persistConfig, authReducer)
  export default rootReducer;