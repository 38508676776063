import { request, removeEmptyAttribute } from "../helpers/utility";

let instance = null;

export default class ChecklistResources {
    constructor() {
        if (!instance) {
            this.url = 'checklist';
            this.admin_url = 'admin/checklist'
            instance = this;
        }
        return instance;
    }

    get() {
        return request.GET(this.url)
    }
    get_admin() {
        return request.GET(this.admin_url)
    }

    post(body) {
        let post_body = { ...body };
        delete post_body['touched'];
        delete post_body['loginTouched'];
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.url, post_body)
    }
    admin_post(body) {
        let post_body = { ...body };
        delete post_body['touched'];
        delete post_body['loginTouched'];
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.admin_url, post_body)
    }    

    put(body) {
        let put_body = { ...body };
        //put_body['role_id'] = put_body['role']['value'];
        delete put_body['role']
        put_body = removeEmptyAttribute(put_body);
        return request.PUT(this.url + "/" + put_body['email'], put_body)
    }
    
    admin_put(body) {
        let put_body = { ...body };
        delete put_body['role']
        put_body = removeEmptyAttribute(put_body);
        return request.PUT(this.admin_url + "/" + put_body['email'], put_body)
    }   
}