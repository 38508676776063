import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import { AgChartsReact } from 'ag-charts-react';
import { toast } from "react-toastify";
import ChecklistResources from "../resources/ChecklistResources";
import {ACCESS_DENIED} from '../Messages'
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import { connect } from "react-redux";

function calculateChartData(item_names, checkedItems) {
    return item_names.map((name) => ({
      name: name,
      count: checkedItems.includes(name) ? 1 : 0,
    }));
  }

function getData(item_names, checkedItems) {

    return [
      {
        name: 'Covered',
        count: 0,
      },
      {
        name: 'Not Covered',
        count: 10,
      },
    ];
  }


function ProductionReady({isLoggedIn, loggedInUser, logout}){
    const resources = new ChecklistResources()
    const data = getData();
    const total = data.reduce((sum, d) => sum + d.count, 0);
    const percentage = (value) => `${((value / total) * 100).toFixed()}%`;

    const [checkedItems, setCheckedItems] = useState([]);
    const [atemItems, setAtemItems] = useState([])
    const [propresenterItems, setPropresenterItems] = useState([])
    const [streamingItems, setStreamingItems] = useState([])
    const [directingItems, setDirectingItems] = useState([])
    const [options, setOptions] = useState({
        data,
        series: [
          {
            type: 'pie',
            angleKey: 'count',
            fills: ['#35ab7c', '#dff3ea'],
            strokeWidth: 0,
            innerRadiusOffset: -20,
            innerLabels: [
              {
                text: percentage(data[0].count),
                color: '#35ab7c',
                fontSize: 72,
              },
              {
                text: 'Ready',
                fontSize: 24,
                margin: 4,
              },
            ],
            innerCircle: {
              fill: '#dff3ea',
            },
          },
        ],
      });

    const [items, setItems] = useState([])

    const loadChecklistItems = () => {
        return resources.get_admin()
    }
  
    useEffect(() => {
        loadChecklistItems().then(response => { 
        setItems(response['body']); 
        setAtemItems(response['body'].filter(item => item['item_classification'] === 'Atem' )); 
        setPropresenterItems(response['body'].filter(item => item['item_classification'] === 'ProPresenter' )); 
        setStreamingItems(response['body'].filter(item => item['item_classification'] === 'Streaming' )); 
        setDirectingItems(response['body'].filter(item => item['item_classification'] === 'Directing' )); 

        }).catch((err) => {
            toast.error("Network error: " + err)
        });
    }, []);

    const handleCheckboxChange = (checkitem) => {
        const isChecked = checkedItems.includes(checkitem);
        const newCheckedItems = isChecked
          ? checkedItems.filter((item) => item !== checkitem)
          : [...checkedItems, checkitem];
      
        setCheckedItems(newCheckedItems);
      
        const item_names = items.map((item) => item['name']);
        const newData = calculateChartData(item_names, newCheckedItems);

        const total = Number(newData.reduce((sum, d) => sum + d.count, 0));
        const percentage = Math.round((total/(Number(items.length))) * 100);
      
        setOptions({
          ...options,
          data: newData,
          series: [
            {
              ...options.series[0], // Keep existing series properties
              innerLabels: [
                {
                  text:`${percentage.toString()+'%'}`,
                  color: '#35ab7c',
                  fontSize: 42,
                },
                {
                  text: 'Ready',
                  fontSize: 18,
                  margin: 4,
                },
              ],
            },
          ],
        });
      };

   
    
    return(
        
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Production Checklist</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            } 
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className="spacer-20"></div>

            {/* <!--Main Content> */}
            {
              isLoggedIn ?  
            <div id="main-container"  style={{marginLeft : "30px"}}>
            
                <div className="container"   style={{width : "40%", float:"left", padding:"20px", border:"2px solid"}}>
                    <h3 className="block-title">Complete checklist before go live</h3>
                    <div className="spacer-20"></div>
                    <h3 style={{color: "#ffa800"}} className="block-title"><strong>ATEM</strong></h3>
                    {atemItems.map((checkitem, index) => {
                        return (
                            <label key = {index}  style={{ display:"flex", alignItems:"center", marginTop:"15px"}}>
                                <input type="checkbox" style={{ marginRight:"10px" }}
                                 onChange={() => handleCheckboxChange(checkitem.name)} 
                                 checked={checkedItems.includes(checkitem.name)}
                                />
                                <span>{checkitem['name']}</span>
                            </label>
                        )
                    } )}
                    <div className="spacer-20"></div>
                    <h3 style={{color: "#ffa800"}} className="block-title"><strong>PROPRESENTER</strong></h3>
                    {propresenterItems.map((checkitem, index) => {
                        return (
                            <label key = {index}  style={{ display:"flex", alignItems:"center", marginTop:"15px"}}>
                                <input type="checkbox" style={{ marginRight:"10px" }}
                                 onChange={() => handleCheckboxChange(checkitem.name)} 
                                 checked={checkedItems.includes(checkitem.name)}
                                />
                                <span>{checkitem['name']}</span>
                            </label>
                        )
                    } )}
                    <div className="spacer-20"></div>
                    <h3 style={{color: "#ffa800"}} className="block-title"><strong>DIRECTING</strong></h3>
                    {directingItems.map((checkitem, index) => {
                        return (
                            <label key = {index}  style={{ display:"flex", alignItems:"center", marginTop:"15px"}}>
                                <input type="checkbox" style={{ marginRight:"10px" }}
                                 onChange={() => handleCheckboxChange(checkitem.name)} 
                                 checked={checkedItems.includes(checkitem.name)}
                                />
                                <span>{checkitem['name']}</span>
                            </label>
                        )
                    } )}
                    <div className="spacer-20"></div>
                    <h3 style={{color: "#ffa800"}} className="block-title"><strong>LIVE STREAMING</strong></h3>
                    {streamingItems.map((checkitem, index) => {
                        return (
                            <label key = {index}  style={{ display:"flex", alignItems:"center", marginTop:"15px"}}>
                                <input type="checkbox" style={{ marginRight:"10px" }}
                                 onChange={() => handleCheckboxChange(checkitem.name)} 
                                 checked={checkedItems.includes(checkitem.name)}
                                />
                                <span>{checkitem['name']}</span>
                            </label>
                        )
                    } )}

                                   
                </div>

                <div className="content" style={{width : "60%", height : "150%", float:"left", padding:"20px"}}>
                  <div className="spacer-60"></div>
                  <div className="spacer-60"></div>
                  <div className="spacer-60"></div>
                  <div className="spacer-60"></div>
                  <div className="spacer-60"></div>
                    <AgChartsReact options={options}/>
                </div> 
                        
            </div>: <></>         
          }
            
        </Fragment>
        
    )
}

const mapStateToProps = (state) => {
  return{
     isLoggedIn: state.authReducer.isLoggedIn,
     loggedInUser: state.authReducer.user
  };
  
};

const mapDispatchToProps = {
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductionReady);