import React, { Fragment, useState, useEffect } from "react";
import Footer from "../layouts/Footer";
import Topbar from "../layouts/TopBar";
import EventResources from "../resources/EventResources";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { connect } from "react-redux";
import Slider from 'react-slick';

function Index({isLoggedIn, loggedInUser}){
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        centerPadding: '50px', // Add padding on the sides
        slidesToShow: 3, // Adjust based on your need
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      };

    const [events, setEvents] = useState([])

    const loadEvents = () => {
        let resources = new EventResources()
        return resources.get_adminhomepage()
    }

    useEffect(() => {
        loadEvents().then(response => { 
            setEvents(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
    }, []);
    return(
	<Fragment>	
		{/* <!-- Topbar --> */}
		<header className="topbar">
			<div style={{backgroundColor:"#ffa800"}} className="container">
				<ul className="social-icons topmenu pull-right">
                    <li className="facebook"><a href="https://www.facebook.com/TLChurchLondon/" target="_blank"><i className="fa fa-facebook-f"></i></a></li>
					<li className="instagram"><a href="https://www.instagram.com/tlchurchlondon/" target="_blank"><i className="fa fa-instagram"></i></a></li>
					<li className="youtube"><a href="https://www.youtube.com/@TheLibertyChurchLondon" target="_blank"><i className="fa fa-youtube-play"></i></a></li>
				</ul>
				
				<div className="header-info-col">
					<i className="fa fa-envelope-o"></i> <strong>av@thelibertychurchlondon.com</strong>
				</div>
			</div>
		</header>		
        <Topbar/>
	{/* <!-- Hero Area --> */}
    <div className="hero-area">
    	{/* <!-- Start Hero Slider --> */}
      	<div className="flexslider heroflex hero-slider"  data-autoplay="yes" data-pagination="no" data-arrows="yes" data-style="fade" data-pause="yes">
            <ul className="slides" >
                <li className="parallax" style={{backgroundImage:"url(https://i.imgur.com/WyeViKy.jpg)"}}>
                    {/* image size 1280 */}
                	<div className="flex-caption">
                    	<div className="container">
                        	<div className="flex-caption-table">
                            	<div className="flex-caption-cell">
                                	<div className="flex-caption-text">
                                        {/* <h2 style={{color:"#0096FF"}}><strong>The Media Team of<br/>The Liberty Church London</strong></h2> */}
                                        {/* <p style={{color:"#ffa800"}}><strong>We help drive the vision<br/>of the Liberty Church globally<br/>by pushing our services through all digitial means</strong></p> */}
                                        <a style={{backgroundColor: "#003165"}} href="/about" className="btn btn-primary">Who are We</a>
                                    </div>
                               	</div>
                          	</div>
                        </div>
                    </div>
                </li>
                {/* <li className="parallax" style={{backgroundImage:"url(assets/images/testjpeg1280.jpg)"}}>
                	<div className="flex-caption">
                    	<div className="container">
                        	<div className="flex-caption-table">
                            	<div className="flex-caption-cell">
                                	<div className="flex-caption-text text-align-center">
                                        <h2>Make a difference for people<br/>who needs it the most</h2>
                                        <a href="/about" className="btn btn-primary">Who are We</a>
                                    </div>
                               	</div>
                          	</div>
                        </div>
                    </div>
                </li>
                 */}
          	</ul>
            <ul className="flex-direction-nav"> 
                <li>
                    <a className="flex-prev" href="#">
                        
                    </a>
                </li>
                <li>
                    <a className="flex-next" href="#">
                       
                    </a>
                </li>

            </ul>
       	</div>
        {/* <!-- End Hero Slider --> */}
    </div>
    <div style={{backgroundColor: "#003165"}} className="featured-links row">
    	<a href="https://www.thelibertychurchlondon.com/" target="_" className="featured-link col-md-4 col-sm-4">
        	<span>Main Site</span>
        	<strong>The Liberty Church</strong>
        </a>
    	<a href="/events" className="featured-link col-md-4 col-sm-4">
        	<span>View Upcoming </span>
        	<strong>Events involving Media</strong>
        </a>
    	<a href="/mediabooking" className="featured-link col-md-4 col-sm-4">
        	<span>Have an Upcoming Event</span>
        	<strong>Request the Media Team</strong>
        </a>
    </div>	

	{/* <!-- Main Content --> */}
    <div id="main-container">
    	<div className="content">       	
        
            <div className="spacer-50"></div>
            <div  className="featured-blocks padding-tb75 lgray-bg">
        		<div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <a href="/teamdistribution" className="featured-block">
                                <img src="https://i.imgur.com/SZZHSuN.jpgR" alt="" className="fblock-image"/>
                                <span className="fblock-image-overlay"></span>
                                <span className="fblock-text"><span className="fblock-text-tb">
                                    <span className="fblock-text-tbcell"><strong>Team Distribution across Locations</strong></span>
                                </span></span>
                            </a>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <a href="/about" className="featured-block">
                                <img src="https://i.imgur.com/502vhCZ.jpg" alt="" className="fblock-image"/>
                                <div className="fblock-image-overlay"></div>
                                <span className="fblock-text"><span className="fblock-text-tb">
                                    <span className="fblock-text-tbcell"><strong>Media Team Members</strong></span>
                                </span></span>
                            </a>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <a href="https://www.thelibertychurchlondon.com/how-to-give/" target="_" className="featured-block">
                                <img src="https://i.imgur.com/1H0Hg7v.png" alt="" className="fblock-image"/>
                                <div className="fblock-image-overlay"></div>
                                <span className="fblock-text"><span className="fblock-text-tb">
                                    <span className="fblock-text-tbcell"><strong>Give to the TheLibertyChurch</strong></span>
                                </span></span>
                            </a>
                        </div>
                    </div>
               	</div>
            </div>
            
            <div className="padding-tb75" style={{textAlign:'center'}}>
            	<div className="container" style={{textAlign:'center'}}>
                	<div className="row" style={{textAlign:'center'}}>
                    	<div className="col-md-12" style={{textAlign:'center'}}>
                        	<h2 style={{color: "#003165"}} className="block-title"><strong>Upcoming Events</strong></h2>
                            <div className="spacer-20"></div>
                            	<ul className="events-compact-list">
                                    {events.map((event, id) => (
                                        event['team_members_only'] === 'False' ?
                                            <li key={id} className="event-list-item">
                                            <span style={{backgroundColor: "#ffa800"}} className="event-date">
                                                <span className="date">{moment(event['event_date']).format('DD')}</span>
                                                <span className="month">{moment(event['event_date']).format('MMM')}</span>
                                                <span className="year">{moment(event['event_date']).format('YYYY')}</span>
                                            </span>
                                            <div className="event-list-cont">
                                                <span className="meta-data">{moment(event['start_time']).format('dddd, HH:mm')} - {moment(event['end_time']).format('HH:mm')}</span>
                                                <h4 className="post-title"><a style={{color: "#003165"}} href={"/eventsdetails?id=" + event['id']}>{event['name']}</a></h4>
                                                <p>{event['description']}</p>
                                                <a href={"/eventsdetails?id=" + event['id']} className="btn btn-default">Details</a>
                                            </div>
                                        </li>  :<></>    
                                        ))}                               	
                                </ul>
                        </div>
                        <div className="spacer-40 visible-sm visible-xs"></div>
                    	
                    </div>
                </div>
            </div>
            {
                isLoggedIn ? 
                <div className="parallax parallax5 parallax-light text-align-center padding-tb100" style={{backgroundColor:"#ffa800"}}>
                    <h2><strong>Readiness checks<br/>before going Live</strong></h2>
                    <a href="/readinesscheck" className="parallax-icon-link"><i className="fa fa-5x fa-play-circle-o"></i></a>
                </div>:<></>
            }
            
            {
                isLoggedIn ? 
                <div className="padding-tb75 padding-b0">
            	<div className="container">
                	<div className="text-align-center">
                  		<h2 className="block-title block-title-center">NEW TO THE TEAM</h2>
                    </div>
                    <div className="spacer-20"></div>
                    <Slider {...settings}>                            
                                
                                    <div style={{marginRight: "90"}} className="grid-item blog-grid-item format-standard">
                                        <div className="grid-item-inner">
                                            <a href="/documentation"  className="media-box">
                                            <img src="https://i.imgur.com/9hD2YTQ.jpg" style={{width: "100%", height:"100%", objectFit: "cover", display:"block", maxWidth:"100%", top:"0", left:"0"}}/>
                                            </a>
                                            <div className="grid-item-content">
                                                <h3 className="post-title"><a style={{color:"#ffa800"}}  href="/documentation"><strong>Welcome and Overview</strong></a></h3>
                                            </div>
                                        </div>
                                    </div>
                                    
                                
                                
                                    <div style={{marginRight: "90"}} className="grid-item blog-grid-item format-standard">
                                        <div className="grid-item-inner">
                                            <a href="https://drive.google.com/file/d/1Crkl9sFW-fn8m2TRQyVJ64uECOv3iUp3/view" target="_blank" className="media-box">
                                            <img src="https://i.imgur.com/JSCdZou.jpg" style={{width: "100%", height:"100%", objectFit: "cover", display:"block", maxWidth:"100%", top:"0", left:"0"}} alt=""/>
                                            </a>
                                            <div className="grid-item-content">
                                                <h3 className="post-title"><a style={{color:"#ffa800"}}  href="https://drive.google.com/file/d/1Crkl9sFW-fn8m2TRQyVJ64uECOv3iUp3/view" target="_blank"><strong>Media Detailed Operation</strong></a></h3>
                                            </div>
                                        </div>
                                    </div>
                                    
                                
                                
                                    <div style={{marginRight: "90"}} className="grid-item blog-grid-item format-standard">
                                        <div className="grid-item-inner">
                                            <a href="https://docs.google.com/file/d/1K4WyEXa3KMtG6HLFTNwS72aW89RGpszO/edit?usp=docslist_api&filetype=msexcel" target="_blank" className="media-box">
                                            <img src="https://i.imgur.com/c7FoQkn.jpg" style={{width: "100%", height:"100%", objectFit: "cover", display:"block", maxWidth:"100%", top:"0", left:"0"}} alt=""/>
                                            </a>
                                            <div className="grid-item-content">
                                                <h3 className="post-title"><a style={{color:"#ffa800"}}  href="https://docs.google.com/file/d/1K4WyEXa3KMtG6HLFTNwS72aW89RGpszO/edit?usp=docslist_api&filetype=msexcel" target="_blank"><strong>Liberty Point Setup</strong></a></h3>
                                            </div>
                                        </div>
                                    </div>
                                
                                {/* <li className="item">
                                    <div className="grid-item blog-grid-item format-standard">
                                        <div className="grid-item-inner">
                                            <a href="single-event.html" className="media-box">
                                                <img src="assets/images/600400.jpg" alt=""/>
                                            </a>
                                            <div className="grid-item-content">
                                                <h3 className="post-title"><a href="single-post.html">Donate your woolens this winter</a></h3>
                                                <span className="meta-data">Posted on 11th Dec, 2015</span>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                            
                        
                               
                        </Slider>
                        
                    
                </div>
            </div> : <></>
            }            
            
        </div>
    </div>


    
		<Footer/>
   

    </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};


export default connect(mapStateToProps)(Index);
