import { request, removeEmptyAttribute } from "../helpers/utility";

let instance = null;

export default class UserResources {
    constructor() {
        if (!instance) {
            this.url = 'users';
            this.admin_url = 'admin/users'
            this.admin_approve_user_url = 'admin/users/confirmation'
            this.admin_refuse_user_url = 'admin/users/rejection'
            this.admin_disable_user_url = 'admin/users/disableuser'
            this.admin_birthday_url = 'admin/users_birthdays'
            this.user_login_url = 'login'
            this.user_logout_url = 'logout'
            this.user_login_state_url = 'check_login'
            this.password_reset_url = 'users/password_reset';
            this.password_update_url = 'users/password_update';
            this.contact_us_url = 'users/contact_us'
            instance = this;
        }
        return instance;
    }

    get() {
        return request.GET(this.url)
    }
    get_admin() {
        return request.GET(this.admin_url)
    }
    get_admin_birthday() {
        return request.GET(this.admin_birthday_url)
    }


    get_admin_single_user(id, loggedInID){
        return request.GET(this.admin_url + '/' + id + '?loggedinUserID=' + loggedInID )
    }

    post(body) {
        let post_body = { ...body };
        delete post_body['touched'];
        delete post_body['loginTouched'];
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.url, post_body)
    }
    admin_post(body) {
        let post_body = { ...body };
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.admin_url, post_body)
    }
    admin_approve_user_post(body) {
        let post_body = { ...body };
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.admin_approve_user_url, post_body)
    }
    admin_refuse_user_post(body) {
        let post_body = { ...body };
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.admin_refuse_user_url, post_body)
    }

    admin_disable_user_post(body) {
        let post_body = { ...body };
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.admin_disable_user_url, post_body)
    }

    post_contact_us(body) {
        let post_body = { ...body };
        delete post_body['touched'];
        delete post_body['loginTouched'];
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.contact_us_url, post_body)
    }

    put(body) {
        let put_body = { ...body };
        //put_body['role_id'] = put_body['role']['value'];
        delete put_body['role']
        put_body = removeEmptyAttribute(put_body);
        return request.PUT(this.url + "/" + put_body['email'], put_body)
    }

    user_post_login(body) {
        let post_body = { ...body };
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.user_login_url, post_body)
    }

    user_logout() {
        return request.GET(this.user_logout_url)
    }

    user_check_login_state() {
        return request.GET(this.user_login_state_url)
    }
    
    admin_put(body) {
        let put_body = { ...body };
        delete put_body['role']
        put_body = removeEmptyAttribute(put_body);
        return request.PUT(this.admin_url + "/" + put_body['email'], put_body)
    }

    post_password(body) {
        let post_body = { ...body };
        delete post_body['resetFieldTouched'];
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.password_reset_url, post_body)
    }

    put_password(body) {
        let put_body = { ...body };
        delete put_body['touched']
        put_body = removeEmptyAttribute(put_body);
        return request.PUT(this.password_update_url, put_body)
    }
}