import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import EventResources from "../resources/EventResources";
import { toast } from "react-toastify";
import moment from "moment/moment";
import {ACCESS_DENIED} from '../Messages'

function MediaEvents({isLoggedIn, loggedInUser}){
    const [events, setEvents] = useState([])

    const loadEvents = () => {
        let resources = new EventResources()
        return resources.get_admin()
    }

    useEffect(() => {
        loadEvents().then(response => { 
            setEvents(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
    }, []);

    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Media Events</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Main Content --> */}
            <div id="main-container">
                <div className="content">
                    {
                        isLoggedIn ?                     
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 content-block">
                                <ul className="events-list">
                                    {events.map((event) => (    
                                        event['team_members_only'] === 'True' ?
                                        
                                        <li className="event-list-item">                                             

                                            <span style={{backgroundColor: "#ffa800"}} className="event-date">
                                            <span className="date">{moment(event['event_date']).format('DD')}</span>
                                            <span className="month">{moment(event['event_date']).format('MMM')}</span>
                                            <span className="year">{moment(event['event_date']).format('YYYY')}</span>
                                            </span>
                                               
                                        
                                            <div className="event-list-cont">
                                                <span className="meta-data">{moment(event['start_time']).format('dddd, HH:mm')} - {moment(event['end_time']).format('HH:mm')}</span>
                                                <h4 className="post-title"><a href={"/eventsdetails?id=" + event['id']}>{event['name']}</a></h4>
                                                <p>{event['description']}</p>
                                                <a href={"/eventsdetails?id=" + event['id']} className="btn btn-default">Details</a>
                                            </div>
                                        </li>  : <></>    
                                    ))}                                                                   
                                </ul>
                                {/* <!-- Page Pagination --> */}
                                {/* <nav>
                                    <ul className="pagination pagination-lg">
                                        <li>
                                            <a href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li className="active"><a href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li>
                                            <a href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav> */}
                            </div>
                            
                            {/* <!-- Sidebar --> */}
                            {/* <div className="col-md-4 sidebar-block">
                                <div className="widget sidebar-widget widget_categories">
                                    <h3 className="widgettitle">Event Categories</h3>
                                    <ul>
                                        <li><a href="#">Education</a> (3)</li>
                                        <li><a href="#">Environment</a> (1)</li>
                                        <li><a href="#">Global warming</a> (6)</li>
                                        <li><a href="#">Water</a> (4)</li>
                                        <li><a href="#">Wild life</a> (2)</li>
                                        <li><a href="#">Small business</a> (12)</li>
                                    </ul>
                                </div>                               
                            </div> */}
                        </div>
                    </div> : <></>
                }
                </div>
            </div>
            <Footer/>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaEvents);
