import { request } from "../helpers/utility";

let instance = null;

export default class RotaResources {
    constructor() {
        if (!instance) {
            this.url = 'admin/rota/';
            this.rotadatesurl = 'admin/rota/dates';
            this.rotaroles = 'admin/rota-roles';
            instance = this;
        }
        return instance;
    }

    get_rota_detail(date) {
        return request.GET(this.url + date)
    }

    get_rota_dates() {
        return request.GET(this.rotadatesurl)
    }

    get_rota_roles() {
        return request.GET(this.rotaroles)
    }
   
}