import { request, removeEmptyAttribute } from "../helpers/utility";

let instance = null;

export default class LocationResources {
    constructor() {
        if (!instance) {
            this.url = 'locations';
            instance = this;
        }
        return instance;
    }

    get() {
        return request.GET(this.url)
    }
   
}