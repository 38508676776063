import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import UserResources from "../resources/UserResources";
import { toast } from "react-toastify";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
//import 'react-pro-sidebar/dist/css/styles.css';
import { Link } from 'react-router-dom';
import zIndex from "@mui/material/styles/zIndex";

const settings = {
    width: 250, /* default expanded width */
    transition: 'left 0.3s',
    left: 0, /* keep sidebar visible */
    responsive: [
      
      {
        breakpoint: 600,
        settings: {
            width: '350px',
            left: '-250px', /* hide sidebar off-screen */
            position: 'fixed', /* fixed position to overlay content */
            height: '100vh', /* full height */
            zIndex: 999, /* high index to be on top */
        }
      },
      {
        breakpoint: 480,
        settings: {
            width: '350px',
            left: '-250px', /* hide sidebar off-screen */
            position: 'fixed', /* fixed position to overlay content */
            height: '100vh', /* full height */
            zIndex: 999, /* high index to be on top */
        }
      }
    ]
  };

function Topbar({isLoggedIn, loggedInUser, logout}){
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const userResources = new UserResources()        


    const handleLogOut = () => {
        userResources.user_logout().then(res => {      
            if (res.error.length > 1) {
                alert("Logout failed")            
            } else {           
                logout()
                alert("Successfully logged out")
            }}).catch((err) => {
                toast.error("Network Error: " + err);
            });     
  
      };
    
    const handleMenuToggle = () => {
        // Implement logic to toggle mobile menu visibility
        setIsMenuOpen(!isMenuOpen); // Toggle the menu state
    };

    return(
        <Fragment>
            <div className="site-header-wrapper">
        {/* <!-- Site Header --> */}
        <header className="site-header">
            <div className="container" style={{backgroundColor:"#003165"}}>
                <div className="site-logo">
                    <a href="/" className="default-logo"><img src="https://i.imgur.com/CBbomjXt.jpg" alt="Logo" style={{width: "100%", height:"50px", objectFit: "cover"}}/></a>
                    <a href="/" className="default-retina-logo"><img src="https://i.imgur.com/CBbomjXt.jpg" alt="Logo" style={{width: "100%", height:"50px"}}/></a>
                    <a href="/" className="sticky-logo"><img src="https://i.imgur.com/CBbomjXt.jpg" alt="Logo" style={{width: "100%", height:"50px", objectFit: "cover"}}/></a>
                    <a href="/" className="sticky-retina-logo"><img src="https://i.imgur.com/CBbomjXt.jpg" alt="Logo" style={{width: "100%", height:"50px", objectFit: "cover"}}/></a>
                </div>
             	<a href="#" className="visible-sm visible-xs" id="menu-toggle" onClick={handleMenuToggle}><i className="fa fa-bars"></i></a> 

                  {  
                    isMenuOpen &&

                    <div>
                        <Sidebar {...settings} style={{color: "#003165"}}>
                            <Menu>
                                
                                <MenuItem component={<Link to="/" />}> Home </MenuItem>
                                <SubMenu label="Events">
                                    <MenuItem component={<Link to="/events" />}> Upcoming Events</MenuItem>
                                    <MenuItem component={<Link to="/mediabooking" />}> Book Media Team</MenuItem>
                                    {
                                        isLoggedIn ? <MenuItem component={<Link to="/mevents" />}> Media Only Events</MenuItem>:<></>                                        
                                    }
                                    
                                </SubMenu>
                                {
                                    isLoggedIn ? 
                                    <>
                                    <MenuItem component={<Link to="/onboarding" />}> Training</MenuItem>
                                    <SubMenu label="The Team">
                                        <MenuItem component={<Link to="/about" />} >About us</MenuItem>
                                        <MenuItem component={<Link to="/teamdistribution" />} > Distribution</MenuItem>
                                        <MenuItem component={<Link to="/rota" />} >Rota</MenuItem>
                                        <MenuItem component={<Link to="/absences" />} >Absences</MenuItem>                                        
                                        <MenuItem component={<Link to="/birthdays" />}>Birthdays</MenuItem>
                                        <MenuItem component={<Link to="/improvements" />}> Improvements </MenuItem>
                                        <MenuItem component={<Link to="/contact" />}> Contact us </MenuItem>
                                        {
                                            loggedInUser['role.name'] === 'admin' ?
                                            <MenuItem> <a style={{color:'#003165'}} href="https://docs.google.com/spreadsheets/d/1lucUU4zEBaDV_JQz1YudZ-FzKSSEglX1/edit?usp=sharing&ouid=109519832316473962023&rtpof=true&sd=true" target="_blank">Skills Matrix</a></MenuItem>
                                            :<></>
                                        }
                                        
                                    </SubMenu></>  :
                                    <></>                                     
                                }
                                <MenuItem component={<Link to="/bestpractices" />}> Best Practices</MenuItem>
                                <MenuItem component={<Link to="/contact" />}> Contact us</MenuItem>                           
                                                               
                                {
                                     loggedInUser !== null && loggedInUser['role.name'] === 'admin'?
                                     
                                     <SubMenu label="Admins panel">
                                        <MenuItem><a style={{color:'#003165'}} href="/manageusers">Manage Users</a></MenuItem>
                                        <MenuItem><a style={{color:'#003165'}} href="#">Manage Events</a></MenuItem>
                                        <MenuItem><a style={{color:'#003165'}} href="#">Team Distribution</a></MenuItem>
                                        <MenuItem><a style={{color:'#003165'}} href="#">Production Checklist</a></MenuItem>
                                        <MenuItem><a style={{color:'#003165'}} href="#">Prompt Presenters</a></MenuItem>
                                        <MenuItem><a style={{color:'#003165'}} href="/broadcastemail">Broadcast Email</a></MenuItem>
                                     </SubMenu>
                                     :null
                                }
                                
                                {
                                    isLoggedIn ? <MenuItem><a href="#" style={{color:'#003165'}} onClick={handleLogOut}>Logout</a> </MenuItem> : <MenuItem><a href="/registration" style={{color:'#003165'}}>Login/Register</a></MenuItem>
                                }                                
                            </Menu>
                        </Sidebar >
                    </div>
                }           

                    <div style={{justifyContent:"center", alignItems:"center"}}>
                        <ul className="sf-menu dd-menu pull-right" role="menu" style={{justifyContent:"center", alignItems:"center"}}>
                            <li><a style={{color:'#FFFFFF'}} href="/"><strong>Home</strong></a></li>
                            <li><a style={{color:'#FFFFFF'}} href="/events"><strong>Events</strong></a>
                                <ul style={{justifyContent:"center", alignItems:"center"}}>
                                    <li><a href="/events">Upcoming Events</a></li>
                                    <li><a href="/mediabooking">Book Media Team</a></li>
                                    {isLoggedIn ? <li><a href="/mevents">Media Only Events</a></li> : <></>}
                                </ul>
                            </li>

                               {isLoggedIn && (
                                        <>
                                            <li>
                                            <a style={{color:'#FFFFFF'}} href="/about"><strong>The Team</strong></a>
                                            <ul>
                                                <li><a href="/about">About Us</a></li>
                                                <li><a href="/teamdistribution">Team Distribution</a></li>
                                                <li><a href="/rota">Rota</a></li>
                                                <li><a href="/absences">Team Member Absences</a></li>
                                                { loggedInUser['role.name'] === 'admin' ?
                                                <li><a href="https://docs.google.com/spreadsheets/d/1lucUU4zEBaDV_JQz1YudZ-FzKSSEglX1/edit?usp=sharing&ouid=109519832316473962023&rtpof=true&sd=true" target="_blank">Team Skills Matrix</a></li> :
                                                <></>
                                                }                                                
                                                <li><a href="/birthdays">Team Birthdays</a></li>
                                                <li><a href="/improvements">Process Improvements</a></li>
                                                <li><a href="/contact">Contact us</a></li>
                                            </ul>
                                            </li>
                                            <li><a style={{color:'#FFFFFF'}} href="/onboarding"><strong>Training and Onboarding</strong></a></li>
                                            <li><a style={{color:'#FFFFFF'}} href="/readinesscheck"><strong>Production Checklist</strong></a></li>

                                            {loggedInUser && loggedInUser['role.name'] === 'admin' && (
                                            <li>
                                                <a style={{color:'#FFFFFF'}} href="#"><strong>Admins Panel</strong></a>
                                                <ul className="dropdown">
                                                <li><a href="/manageusers">Manage Users</a></li>
                                                <li><a href="#">Manage Events</a></li>
                                                <li><a href="#">Team Distribution</a></li>
                                                <li><a href="#">Production Checklist</a></li>
                                                <li><a href="#">Prompt Presenters</a></li>
                                                <li><a href="/broadcastemail">Broadcast Email</a></li>
                                                </ul>
                                            </li>
                                            )}
                                            <li><a style={{color:'#FFFFFF'}} href="#" onClick={handleLogOut}><strong>Logout</strong></a></li>
                                        </>
                                    )}
                                    {!isLoggedIn && (
                                        <>
                                            <li><a style={{color:"#FFFFFF"}} href="/bestpractices"> Best Practices</a></li>
                                            <li><a style={{color:'#FFFFFF'}} href="/contact"><strong>Contact us</strong></a></li>
                                            <li><a style={{color:'#FFFFFF'}} href="/registration"><strong>Login/Register</strong></a></li>
                                        </>
                                            
                                    )}

                        </ul>
                 </div> 
                
            </div>
        </header>
    </div>

    </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);

