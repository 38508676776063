import React, { Fragment } from "react";
import Topbar from "../layouts/TopBar";
import {ACCESS_DENIED} from '../Messages'
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import { connect } from "react-redux";

function Onboarding({isLoggedIn, loggedInUser, logout}){
    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
                <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Training and Onboarding</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }                            
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Main Content --> */}
            <div id="main-container">
            <div class="spacer-20"></div>           
                <div class="content">
                {
                    isLoggedIn ?                    
                    <div class="container">
                        <h3 className="post-title"><p style={{color:"#ffa800"}}>Please Take Time to Read about our Processes, Best Practices and Helpful Training materials</p></h3>
                        <div class="spacer-20"></div>
                        <div class="spacer-20"></div>
                        
                        <div class="row">
                            <div class="col-md-8 content-block">
                                <div class="blog-list-item format-standard">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-4">
                                            <a href="/documentation" class="media-box grid-featured-img">
                                                <img src="https://i.imgur.com/9hD2YTQ.jpg" style={{width: "600", height:"400"}} alt=""/>
                                            </a>
                                        </div>
                                        <div class="col-md-8 col-sm-8">
                                            <h3><a style={{color: "#003165"}} href="/documentation">Welcome and Overview</a></h3>
                                            <span class="meta-data grid-item-meta"><i class="fa fa-calendar"></i> Posted on 11th April, 2024</span>
                                            <div class="grid-item-excerpt">
                                                <p>A quick Overview of the work we do, setup and the expectations</p>
                                            </div>
                                            <a style={{color: "#ffa800"}} href="/documentation" class="basic-link">Read more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-list-item format-standard">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-4">
                                            <a href="/documentation" class="media-box grid-featured-img">
                                                <img src="https://i.imgur.com/JSCdZou.jpg" style={{width: "600", height:"400"}} alt=""/>
                                            </a>
                                        </div>
                                        <div class="col-md-8 col-sm-8">
                                            <h3><a style={{color: "#003165"}} href="https://drive.google.com/file/d/1Crkl9sFW-fn8m2TRQyVJ64uECOv3iUp3/view?usp=drive_link" target="_blank">Standard of Operation documentation</a></h3>
                                            <span class="meta-data grid-item-meta"><i class="fa fa-calendar"></i> Posted on 11th April, 2024</span>
                                            <div class="grid-item-excerpt">
                                                <p>Details of how we operate, setups and trainings.</p>
                                            </div>
                                            <a style={{color: "#ffa800"}} href="https://drive.google.com/file/d/1Crkl9sFW-fn8m2TRQyVJ64uECOv3iUp3/view?usp=drive_link" target="_blank" class="basic-link">Read more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-list-item">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-4">
                                            <a href="https://youtube.com/playlist?list=PLF96tUxA556grge9FXpnRsiytzmntDLYx&si=zTc-RfDcuVSPxqIx" target="_blank" class="media-box grid-featured-img">
                                                <img src="https://i.imgur.com/tjUICzq.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div class="col-md-8 col-sm-8">
                                            <h3><a style={{color: "#003165"}} href="https://youtube.com/playlist?list=PLF96tUxA556grge9FXpnRsiytzmntDLYx&si=zTc-RfDcuVSPxqIx" target="_blank">Video Training playlist</a></h3>
                                            <span class="meta-data grid-item-meta"><i class="fa fa-calendar"></i> Posted on 11th Jan, 2023</span>
                                            <div class="grid-item-excerpt">
                                                <p>A set of videos that give an introduction to Production at a general level and more details on equipments setup</p>
                                            </div>
                                            <a style={{color: "#ffa800"}} href="https://youtube.com/playlist?list=PLF96tUxA556grge9FXpnRsiytzmntDLYx&si=zTc-RfDcuVSPxqIx" target="_blank" class="basic-link">Watch here</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="blog-list-item">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-4">
                                            <a style={{color: "#003165"}} href="https://docs.google.com/file/d/1K4WyEXa3KMtG6HLFTNwS72aW89RGpszO/edit?usp=docslist_api&filetype=msexcel" target="_blank" class="media-box grid-featured-img">
                                                <img src="https://i.imgur.com/c7FoQkn.jpg" style={{width: "600", height:"500"}} alt=""/>
                                            </a>
                                        </div>
                                        <div class="col-md-8 col-sm-8">
                                            <h3><a style={{color: "#003165"}} href="https://docs.google.com/file/d/1K4WyEXa3KMtG6HLFTNwS72aW89RGpszO/edit?usp=docslist_api&filetype=msexcel" target="_blank"> Liberty Point Setup</a></h3>
                                            <span class="meta-data grid-item-meta"><i class="fa fa-calendar"></i> Posted on 11th Dec, 2023</span>
                                            <div class="grid-item-excerpt">
                                                <p>A detailed view of the setup we use at Liberty Point- Woodford</p>
                                            </div>
                                            <a style={{color: "#ffa800"}} href="https://docs.google.com/file/d/1K4WyEXa3KMtG6HLFTNwS72aW89RGpszO/edit?usp=docslist_api&filetype=msexcel" target="_blank" class="basic-link">See setup</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="blog-list-item">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-4">
                                            <a href="/bestpractices" class="media-box grid-featured-img">
                                                <img src="https://i.imgur.com/Pw597iW.jpg" alt=""/>
                                            </a>
                                        </div>
                                        <div class="col-md-8 col-sm-8">
                                            <h3><a style={{color: "#003165"}} href="/bestpractices"> Best Practices</a></h3>
                                            <span class="meta-data grid-item-meta"><i class="fa fa-calendar"></i> Posted on 03rd May, 2024</span>
                                            <div class="grid-item-excerpt">
                                                <p>A guide especially for Non Media members when operating in a Live Production Event or Service</p>
                                            </div>
                                            <a style={{color: "#ffa800"}} href="/bestpractices"  class="basic-link">Read Here</a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Page Pagination --> */}
                                {/* <nav>
                                    <ul class="pagination pagination-lg">
                                        <li>
                                            <a href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="active"><a href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li>
                                            <a href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav> */}
                            </div>
                            {/* <div class="col-md-4 sidebar-block">
                                
                                <div class="widget sidebar-widget widget_categories">
                                    <h3 class="widgettitle">Post Categories</h3>
                                    <ul>
                                        <li><a href="#">Education</a> (3)</li>
                                        <li><a href="#">Environment</a> (1)</li>
                                        <li><a href="#">Water</a> (4)</li>
                                        <li><a href="#">Wild life</a> (2)</li>
                                        <li><a href="#">Small business</a> (12)</li>
                                    </ul>
                                </div>
                                <div class="widget sidebar-widget widget_search">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Enter your keywords"/>
                                        <span class="input-group-btn">
                                            <button class="btn btn-primary" type="button"><i class="fa fa-search"></i></button>
                                        </span>
                                    </div>
                                </div>                                
                            </div> */}
                        </div>
                    </div> : <></>
                }
                </div> 
            </div>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);