import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AbsenceResources from "../resources/AbsenceResources";
import UserResources from "../resources/UserResources";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import {ACCESS_DENIED} from '../Messages'

function TeamAbsences({isLoggedIn, loggedInUser}){   
  const [absences, setAbsences] = useState([])
  const [selectedOption, setSelectedOption] = useState({}); 
  const [users, setUsers] = useState([])
  const [absenceReason, setAbsenceReason] = useState('');
  const [dateSelectionRange, setDateSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const today = new Date();

  let absenceResources = new AbsenceResources()
    const loadAbsences = () => {
        let resources = new AbsenceResources()
        return resources.get()
    }

    const loadUsers = () => {
        let userResources = new UserResources()
        return userResources.get_admin()
    }

     // Function to handle dropdown change
     const handleDropdownChange = (event) => {
        setSelectedOption(event.value);
      };      
    
      const handleDateSelectRange = (ranges) => {
        setDateSelectionRange(ranges.selection);
      };;
    
      // Function to handle textbox change
      const handleAbsenceReasonChange = (event) => {
          setAbsenceReason(event.target.value);
      };
    
      // Function to handle button clicks
      const handleAddAbsenceButtonClick = () => { 
        
        if(Object.keys(selectedOption).length === 0)
        {
          alert("Please Select your Name")
          return;
        }

        if(Object.keys(absenceReason).length === 0)
            {
              alert("Please Enter a Reason")
              return;
            }
        let absence = {};
        absence['user_id'] = selectedOption['id']
        if(absenceReason.length > 1 )
        {
            absence['reason'] = absenceReason
        }
        absence['date_added'] = moment( new Date()).format('YYYY-MM-DD HH:mm:ss') 
        absence['start_date'] = moment( dateSelectionRange['startDate']).format('YYYY-MM-DD HH:mm:ss') 
        absence['end_date'] = moment( dateSelectionRange['endDate']).format('YYYY-MM-DD HH:mm:ss') 
        absenceResources.post(absence)        
  
          setTimeout(() => {
              window.location.href = window.location.origin + '/absences';
            }, 200);
      };
  
      // Function to handle button clicks
      const handleDeleteAbsenceButtonClick = (id) => {      
        let absence = {};
        absence['id'] = Number(id)
        absence['user_email'] = loggedInUser['email']

        absenceResources.delete_absence(absence).then(res => {
            if (res.error.length < 1) {
                alert("Absence removed")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/absences';
                  }, 200);
            }else if(res.error === 'You do not have permission to make this change') {
                alert("You do not have permission to make this change.")
            }
             else {
                //toast.error("Operation Failed: " + res.error);
                alert("An Issue occured, please retry")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      
  
      };     

    useEffect(() => {
        loadAbsences().then(response => { 
            setAbsences(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });

        loadUsers().then(response => { 
            setUsers(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
    }, []);

    let members = []  //dropdown values is the user information
    users.map((user, index) => (
        members.push({value: user, label: user['firstname'] + " " + user['lastname'] })
    ))

    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                        {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Team Absences</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>

            <div id="main-container">
                {
                    isLoggedIn ? 
                
                <div className="content">
                    <div className="container">                    
                        <h3 style={{color: "#003165"}}>Add Absences as early as you can</h3>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell><strong>FirstName</strong></TableCell>
                                    <TableCell><strong>LastName</strong></TableCell>
                                    <TableCell><strong>Start Date</strong></TableCell>
                                    <TableCell><strong>End Date</strong></TableCell>
                                    <TableCell><strong>Reason</strong></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {absences.map((absence) => (
                                    <TableRow key={absence['user.firstname']}>
                                    <TableCell>{absence['user.firstname']}</TableCell>
                                    <TableCell>{absence['user.lastname']}</TableCell>
                                    <TableCell>{moment( absence['start_date']).format('DD MMMM YYYY')}</TableCell>
                                    <TableCell>{moment( absence['end_date']).format('DD MMMM YYYY')}</TableCell>
                                    <TableCell>{absence['reason']}</TableCell>
                                    <TableCell>{ loggedInUser !== null && loggedInUser['role.name'] === 'admin'? <button style={{marginLeft: "10px"}} onClick={() => handleDeleteAbsenceButtonClick(absence['id'])} >Remove</button> : <p></p>}</TableCell>                                     
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>    
                        <div className="spacer-40"></div>

                        
                            <div>
                                <div className="row">
                            <Select
                                name='members'
                                id="members"
                                classNamePrefix='select-css-tag'
                                //value={selectedOption}
                                onChange={handleDropdownChange}
                                options={members}
                                placeholder="Team Members"
                                />
                                <div className="spacer-20"></div>

                                    <div className="col-md-6">
                                        <label>Absence Period</label>
                                            <DateRangePicker
                                                    editableDateInputs
                                                    onChange={handleDateSelectRange}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={[dateSelectionRange]}
                                                    minDate={today}
                                                /> 
                                        </div>   
                            
                            <input style={{marginLeft: "10px", marginTop: "10px"}}
                                type="text"
                                value={absenceReason}
                                onChange={handleAbsenceReasonChange}
                                placeholder="Enter Reason"
                            />
                            <button style={{marginLeft: "10px", width:'100px', color: '#003165'}} onClick={handleAddAbsenceButtonClick}>Add</button>
                        </div>   

                            </div>
                           
                        

                            
                    </div>
                </div> : <></>
        }
            </div>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamAbsences);