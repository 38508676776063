import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import UserResources from "../resources/UserResources";
import Select from 'react-select';
import BroadcastEmailResources from "../resources/BroadcastEmailResources";
import { toast } from "react-toastify";
import {ACCESS_DENIED} from '../Messages'

function validate(subject, message) {
    return {
      subject: subject.length === 0,
      message: message.length === 0,
    };
  }


function BroadcastEmail({isLoggedIn, loggedInUser, logout}){
    const [users, setUsers] = useState([])
    const [formData, setFormData] = useState({
        subject: '',
        message:''
      });

    const [excludedUsers, setExcludedUsers] = useState([]); // stores the entire user details

    const handleExcludedUsers = (users) => {
        setExcludedUsers(users)
    };

    const loadUsers = () => {
        let userResources = new UserResources()
        return userResources.get_admin()
    }

    const broadcastemailresources = new BroadcastEmailResources()

    useEffect(() => {        
        loadUsers().then(response => { 
            setUsers(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
        
    }, []);

    const canBeSubmitted = (dataToSubmit) => {
        const errors = validate(dataToSubmit['subject'], dataToSubmit['message']);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return !isDisabled;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        let submitFormData = formData
        // ToDo: Fix this hack: get all data from state
        let excludedUsersDetails =[]        
        excludedUsers.map((role) => (
            excludedUsersDetails.push(role['value']['email'])
        ))
        submitFormData['exclude_users'] = excludedUsersDetails
        if (!canBeSubmitted(submitFormData)){
            alert("Remember to fill out the mandatory fields")
            return
        }
        broadcastemailresources.post(submitFormData).then(res => {
            if (res.error.length < 1) {
                //toast.success("Thanks for registering!  Please check your email to confirm your email address."); 
                alert("Emails Successfully sent out.")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/';
                  }, 200);
            } else {
                alert("Something went wrong, Please try again.")
            }
            }).catch((err) => {
                alert("Something went wrong, Please try again.")
            });

       
      };

    let members = []  //dropdown values is the user information
    users.map((user, index) => (
        members.push({value: user, label: user['firstname'] + " " + user['lastname'] })
    ))


    return(
        <Fragment>
            <Topbar/>

            {/* <!-- Hero Area --> */}
            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                        {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Send Email to the Team</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                        }                            
                        </div>
                    </div>
                </div>
            </div>

    {/* <!-- Main Content --> */}
    <div id="main-container">
 
    	<div className="content">  
            <div className="spacer-35"></div>
            {
                isLoggedIn ?  
        	<div className="container">
            	<div className="row">
                	<div className="col-md-8 content-block">
                    	<form onSubmit={handleSubmit}>
                        	<div className="row">
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <label>Email Subject<span style={{color:"red"}}>*</span></label>
                                        <input type="text" id="subject" name="subject"  value={formData.firstname}
                                                    onChange={handleInputChange} placeholder="Subject*"/>
                                    </div>
                                    <div className="spacer-30"></div>
                                    <div className="form-group">
                                    <label>Exclude from Email:<span style={{color:"red"}}>*</span></label>
                                        <Select
                                        name='members'
                                        id="members"
                                        classNamePrefix='select-css-tag'
                                        isMulti
                                        //value={selectedOption}
                                        onChange={handleExcludedUsers}
                                        options={members}
                                        placeholder="Members - can choose multiple"
                                        />
                                    </div>
                                    
                                </div>
                                <div className="col-md-7">
                                    <div className="form-group">
                                        <textarea cols="6" rows="8" id="message" name="message" value={formData.message}
                                                    onChange={handleInputChange}  className="form-control input-lg" placeholder="Message*"></textarea>
                                    </div>
                                    <input style={{backgroundColor: "#003165"}} id="submit" name="submit" type="submit" className="btn btn-primary btn-lg pull-right" value="Send Message"/>
                              	</div>
                           	</div>
                		</form>
                        <div className="clearfix"></div>
                        <div id="message"></div>
                    </div>                    
                    
                </div>
            </div>
            : <></>
        }
        </div>
    </div>
            

            <Footer/>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastEmail);

