import { request, removeEmptyAttribute } from "../helpers/utility";

let instance = null;

export default class ProcessImprovementsResources {
    constructor() {
        if (!instance) {
            this.url = 'improvements';
            instance = this;
        }
        return instance;
    }

    get() {
        return request.GET(this.url)
    }

    post(body) {
        let post_body = { ...body };
        post_body = removeEmptyAttribute(post_body);
        return request.POST(this.url, post_body)
    }    

    delete_Volunteer(body) {
        let delete_body = { ...body };
        delete_body = removeEmptyAttribute(delete_body);
        return request.Fetch('DELETE', this.url, delete_body, false)
    }

   
  
}