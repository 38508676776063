const Config = {
    site: "TLC Media",
    subTitle: "SubTitle",
    url: "https://www.tlcmedia.netlify.app",
    description: "Tlc Media site",
    keywords: "TLC",
    logo: "/img/logo/adayalogo.png",//"https://i.imgur.com/1UDpSHj.png",
    postPerPage: 8,
    googleAnaltics: "",
    endpoint: "https://tlcmedia-b3cb34e37a69.herokuapp.com/"
}
module.exports = Config;
